.p-form {
  &__form-list {
    border-bottom: 1px solid $accentColor;
  }

  &__form-item {
    &.is-invalid {
      position: relative;
      padding-bottom: 60px;
      border: 1px solid $accentColor;
    }
  }

  // Validation
  &__error {
    bottom: 20px;
    left: 10px;
    padding: 5px 10px;
    color: $accentColor;
  }

  &__submitted {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 2rem;
  }

  &__submitted-group {
    position: relative;
    height: 100%;
    margin-top: 40px;
    text-align: center;

    @include mq(md) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__submitted-title {
    text-align: center;
    font-size: get_vw(18);
    font-weight: bold;

    @include mq(md) {
      margin-top: 4rem;
      font-size: 2.6rem;
    }
  }

  &__submitted-text {
    margin-top: 10px;
    text-align: center;
    font-size: get_vw(14);

    @include mq(md) {
      margin-top: 20px;
      font-size: 1.8rem;
    }
  }

  &__submitted-image {
    width: 90px;
    margin-top: 20px;

    @include mq(md) {
      position: absolute;
      right: 0;
      bottom: -150px;
      width: 160px;
      margin-top: 0;
    }
  }
}

.is-invalid {
  position: relative;
  padding-bottom: 60px;
  border: 5px solid rgba($accentColor, 0.7);
}

.fadeform-enter-active,
.fadeform-leave-active {
  transition: opacity 0.5s;
}

.fadeform-enter,
.fadeform-leave-to {
  height: 0;
  opacity: 0;
}

.fademessage-enter-active,
.fademessage-leave-active {
  transition: opacity 0.5s 0.5s, height 0.5s;
}

.fademessage-enter,
.fademessage-leave-to {
  height: 0;
  opacity: 0;
}
