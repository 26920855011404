@charset "UTF-8";

//=============================================
// Project strength
//=============================================

.p-strength {
  position: relative;
  padding: get_vw(100) get_vw(10);
  background: $accentColor;

  @include mq(md) {
    padding: 6.667vw 10vw 2.8vw;
  }

  &__wrap {
    @include mq(md) {
      display: flex;
    }
  }

  &__title {
    margin-bottom: get_vw(60);
    text-align: center;
    letter-spacing: 2px;
    font-family: Montserrat, bold;
    font-size: get_vw(32);
    line-height: 1.5;
    color: $baseColor;

    @include mq(md) {
      margin-bottom: 3.5vw;
      font-size: 2.133vw;
    }

    &--lg {
      font-size: get_vw(42);

      @include mq(md) {
        font-size: 2.8vw;
      }
    }
  }

  &__text {
    @include mq(md) {
      width: 42.267vw;
      margin-right: 4.4vw;
    }

    &--item {
      padding: get_vw(30) get_vw(20);
      letter-spacing: 1.3px;
      font-size: get_vw(16);
      line-height: get_vw(30);
      border-radius: 0.5rem;
      background: $baseBg;

      @include mq(md) {
        padding: 2vw;
        font-size: 1.067vw;
        line-height: 2vw;
      }
    }

    &--under {
      font-size: get_vw(12);

      @include mq(md) {
        font-size: 0.8vw;
      }
    }
  }

  &__img {
    @include mq(md) {
      width: 33.333vw;
    }
  }

  &__bgImg {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    img {
      position: relative;
      bottom: -0.5vw;
    }
  }
}
