@charset "UTF-8";

//=============================================
// Project reason
//=============================================

.p-reason {
  position: relative;
  margin-bottom: 10rem;
  padding-top: get_vw(40);
  text-align: center;

  @include mq(md) {
    padding-top: 8vw;
  }

  &__bgImg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  &__title {
    margin-bottom: get_vw(40);
    letter-spacing: 2px;
    font-family: "Montserrat", bold;
    font-size: get_vw(32);
    line-height: 1.5;
    color: $mainColor;

    @include mq(md) {
      margin-bottom: 2.667vw;
      font-size: 2.667vw;
    }

    &--lg {
      font-size: get_vw(42);

      @include mq(md) {
        font-size: 3.733vw;
      }
    }

    &--sub {
      font-family: "Montserrat", Bold;
      font-size: get_vw(32);
      line-height: 1.5;

      @include mq(md) {
        font-size: 2.3vw;
      }
    }

    &--subLg {
      letter-spacing: 2px;
      font-size: get_vw(40);
      color: $accentColor;

      @include mq(md) {
        font-size: 3.333vw;
      }
    }
  }

  &__list {
    @include mq(md) {
      display: flex;
      justify-content: center;
      margin-bottom: 4vw;
    }

    &--card {
      width: 100%;

      @include mq(md) {
        width: 25.333vw;
        margin-right: 1.867vw;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--cardNum {
      position: relative;
      top: get_vw(70);
      right: get_vw(10);
      text-align: left;
      font-size: get_vw(60);
      font-weight: 900;
      font-style: italic;
      color: $accentColor;

      @include mq(md) {
        top: 4.66667vw;
        right: 0.66667vw;
        font-size: 4vw;
      }
    }

    &--img {
      position: relative;
      top: get_vw(40);
      margin-bottom: get_vw(20);

      @include mq(md) {
        top: 2.66667vw;
        width: 18.667vw;
        height: 12.5vw;
        margin: 0 auto;
      }
    }

    &--imgItem {
      width: get_vw(250);
    }

    &--cardContent {
      box-sizing: border-box;
      padding: get_vw(60) get_vw(20);
      background: $subBg;

      @include mq(md) {
        box-sizing: border-box;
        min-height: 33rem;
        padding: 4.2vw 1.6vw;
      }

      @include mq(lg) {
        min-height: 43rem;
        padding: 4.2vw 1.6vw;
      }
    }

    &--cardTitle {
      margin-bottom: get_vw(10);
      letter-spacing: 0.17rem;
      font-size: get_vw(22);
      font-weight: bold;
      color: $mainColor;

      @include mq(md) {
        margin-bottom: 1.2vw;
        font-size: 1.467vw;
      }
    }

    &--cardText {
      padding: 0 2rem;
      text-align: left;
      letter-spacing: 0.13rem;
      font-size: get_vw(16);
      line-height: 2;

      @include mq(md) {
        font-size: 1.067vw;
      }
    }

    &--underText {
      padding: 0 2rem;
      text-align: left;
      font-size: get_vw(12);
      line-height: 2rem;

      @include mq(md) {
        font-size: 0.8vw;
      }
    }
  }

  &__tab {
    @include mq(md) {
      width: 66.667vw;
      margin: 0 auto;
    }

    &--list {
      display: flex;
      justify-content: space-between;
      list-style: none;

      @include mq(md) {
        width: 50%;
      }
    }

    &--listItem {
      width: get_vw(82);
      margin-right: get_vw(3);
      padding: get_vw(5);
      font-size: get_vw(18);
      font-weight: bold;
      border: solid 3px #e6e6e6;
      border-bottom: none;
      -webkit-border-radius: 5px 5px 0 0 / 5px 5px 0 0;
      -moz-border-radius: 5px 5px 0 0 / 5px 5px 0 0;
      border-radius: 5px 5px 0 0 / 5px 5px 0 0;
      background: $baseBg;
      color: $mainColor;
      transition: all 0.3s;

      @include mq(md) {
        width: 8vw;
        margin-right: 0.5vw;
        padding: 0.7vw;
        font-size: 1.2vw;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &--listContent {
      min-height: 25rem;
      border: solid 3px #e6e6e6;
      -webkit-border-radius: 0 0 5px 5px / 0 0 5px 5px;
      -moz-border-radius: 0 0 5px 5px / 0 0 5px 5px;
      border-radius: 0 0 5px 5px / 0 0 5px 5px;
      background-color: #fff;
      list-style: none;

      @include mq(md) {
        display: flex;
        padding: 1.333vw 3.333vw;
      }

      &--text {
        @include mq(md) {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
        }
      }
    }

    &--listImg {
      margin: get_vw(30) 0;

      @include mq(md) {
        margin: 0;
      }
    }

    &--listText {
      margin: get_vw(20);
      margin-top: 0;
      text-align: left;
      font-size: get_vw(16);

      @include mq(md) {
        margin: 0;
        margin-right: 2vw;
        font-size: 1.067vw;
      }

      &--head {
        margin-bottom: get_vw(20);

        @include mq(md) {
          margin-bottom: 2vw;
        }
      }
    }
  }

  &__button {
    margin-top: get_vw(40);

    @include mq(md) {
      margin-top: 6rem;
    }
  }
}

.active {
  background: $mainColor;
  color: #fff;
}
