@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700|Noto+Sans+JP:500,700&display=swap");

/* ========================================
  Base
======================================== */

// html5doctor.com Reset Stylesheet v1.6.1
// Last Updated: 2010-09-17
// ------------------------------------------

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: 0;
  border: 0;
  background: transparent;
}

body {
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

a {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  font-size: 100%;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  text-decoration: none;
  background-color: #ff9;
  color: #000;
}

/* change colours to suit your needs */
mark {
  font-weight: bold;
  font-style: italic;
  background-color: #ff9;
  color: #000;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #ccc;
}

input,
select {
  vertical-align: middle;
}

// Base style
// ------------------------------------------

html {
  overflow-y: scroll;
  // font-size: 62.5%;
  font-size: $baseFontSize;
}

body,
input,
button,
select,
textarea {
  font-family: $baseFontFamily;
}

body {
  min-width: 320px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 1.4rem;
  line-height: 1.6;
  background: $baseBg;
  color: $baseColor;

  // 自動カーニング処理
  font-feature-settings: "palt";

  // フォントレンダリング調整
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // デバイス幅での文字サイズ変更防止
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
}

img {
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

// フォーム要素のデフォルトスタイルを削除
// input,
// button,
// textarea,
// select {
//   @include form_reset;
// }

label {
  cursor: pointer;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  text-decoration: none;
  color: $linkColor;
  transition: opacity 0.3s;
}

::selection {
  background: $accentColor;
}

//Firefox
::-moz-selection {
  background: $accentColor;
}
