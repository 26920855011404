@charset "UTF-8";

// Display
// ex) Class Name: .u-dsp-block, .u-dsp-none
// --------------------------------------------------

.u-dsp- {
  &block {
    display: block;
  }

  &none {
    display: none;
  }

  &inline {
    display: inline;
  }

  &ib {
    display: inline-block;
  }
}

.fade-in {
  opacity: 0;
  transition: all 1s;
  transform: translate(0, 50px);

  @include mq(md) {
    opacity: 0;
    transition: all 1s;
    transform: translate(0, 100px);
  }
}

.fade-in.fade-active {
  opacity: 1;
  transform: translate(0, 0);
}

.marker-animation.marker-active {
  background-position: -100% 0.8em;
}

.marker-animation {
  font-weight: bold;
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0), $accentColor 50%);
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0), $accentColor);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0), $accentColor);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0), $accentColor);
  background-image: linear-gradient(left, rgba(255, 255, 255, 0), $accentColor);
  background-position: 0 0.5em;
  background-size: 200% 0.8em;
  background-repeat: repeat-x;
  transition: all 2s ease-out;
}


.u-label{
  position: absolute;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
