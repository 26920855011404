@charset "UTF-8";

.p-footer {
  padding: get_vw(24);

  @include mq(md) {
    padding: 3.733vw 8vw;
  }

  &__inner {
    @include mq(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__container {
    @include mq(md) {
      display: flex;
      align-items: center;
    }
  }

  &__text {
    margin-bottom: get_vw(20);

    @include mq(md) {
      margin-bottom: 0;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    margin-bottom: get_vw(20);
    font-size: get_vw(10);
    list-style: none;

    @include mq(md) {
      margin-bottom: 1.067vw;
      font-size: 1.6rem;
    }

    &--item {
      &:first-child {
        padding-right: get_vw(10);
        border-right: 1px solid $baseColor;

        @include mq(md) {
          padding-right: 1rem;
        }
      }

      &:nth-child(2) {
        padding-left: get_vw(10);

        @include mq(md) {
          padding-left: 1rem;
        }
      }
      //hover animation
      a {
        display: block;
        position: relative;
        text-decoration: none;

        &::after {
          content: "";
          display: block;
          bottom: 0;
          width: 0;
          border-bottom: solid 1px $baseColor;
          -webkit-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }

  &__Copyright {
    text-align: center;
    font-size: get_vw(10);

    @include mq(md) {
      text-align: left;
      font-size: 1.2rem;
    }
  }

  &__sns {
    display: flex;
    justify-content: center;
    margin-bottom: get_vw(24);
    list-style: none;

    @include mq(md) {
      margin-bottom: 0;
    }

    &--item {
      padding-right: get_vw(20);
      transition: all 0.3s;

      @include mq(md) {
        padding-right: 0.667vw;
      }

      &:last-child {
        padding-right: 0;
      }

      &:hover {
        opacity: 0.7;
        transition: all 0.3s;
      }
    }
  }

  &__privacyMark {
    text-align: center;

    @include mq(md) {
      position: relative;
      margin-left: 1.2vw;
      // bottom: 4rem;
    }
  }
}
